.category-nav {
	// overflow: hidden;
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: 0 0 30px 0 rgba($color: #000000, $alpha: 0.15);
	@include respond-to('small') {
		text-align: center;
		font-size: 0;
	}
}

.adhd-img {
	pointer-events: none;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
	min-width: 200px;
	max-width: 420px;
	width: 21vw;
	@include respond-to('1750') {
		width: 19vw;
	}
	@include respond-to('1550') {
		// width: 16vw;
		display: none;
	}
}

.cat-nav-items {
	// display: flex;
	// flex-wrap: wrap;
	font-size: 0;
	// justify-content: center;
}

.cat-nav-item {
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 33.33%;
	border: 1px solid #e6e6e6;
	border-#{$left}: 1px solid transparent;
	border-bottom: none;
	padding: 15px 20px;
	text-align: center;
	transition: background 0.3s ease;
	@include respond-to('medium') {
		padding: 10px;
	}
	@include respond-to('small') {
		width: 50%;
		padding: 5px;
		border-#{$left}: 1px solid #e6e6e6;
		&:first-child {
			border-#{$left}: 1px solid transparent;
		}
	}
	@include respond-to('extra-small') {
		width: 100%;
		border-#{$left}: 1px solid #e6e6e6;
		border-#{$right}: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		&:first-child {
			border-#{$left}: 1px solid #e6e6e6;
			border-top: 1px solid #e6e6e6;
		}
	}
	&:last-child {
		border-#{$left}: 1px solid #e6e6e6;
		@include respond-to('small') {
			border-top: none;
		}
		@include respond-to('extra-small') {
			// border-top: 1px solid #e6e6e6;
		}
	}
	&:hover {
		box-shadow: 0 0 20px 0 rgba($color: #000000, $alpha: 0.2);
		background-color: #5dc5fa;
		& .cat-nav-item__title,
		& .cat-nav-item__desc,
		& .cat-nav-item__link {
			color: #fff;
		}
	}
	&__title {
		display: block;
		font-size: 50px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		transition: color 0.3s ease;
		@include respond-to('1100') {
			font-size: 35px;
		}
		@include respond-to('medium') {
			font-size: 30px;
			margin-bottom: 5px;
		}
		@include respond-to('extra-small') {
			font-size: 25px;
		}
	}
	&__desc {
		font-size: 20px;
		font-weight: 400;
		color: #000;
		transition: color 0.3s ease;
		@include respond-to('medium') {
			font-size: 16px;
		}
	}
	&__link {
		position: relative;
		display: inline-block;
		margin-top: 15px;
		// margin-#{$right}: 5px;
		padding-top: 5px;
		border-top: 1px solid #cec3fd;
		color: #000;
		font-size: 18px;
		font-weight: 700;
		line-height: 1.3;
		transition: color 0.3s ease;
		@include link_no-hover(#000);
		@include respond-to('medium') {
			font-size: 16px;
			margin-top: 10px;
		}
	}
}