// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// font family
$text-family: 'Assistant',
sans-serif !default; //fond sizes
$font-size-base: 20px !default;
$font-size-small: ceil(($font-size-base * 0.8)) !default; // ~16px
$font-size-medium: floor(($font-size-base * 1.25)) !default; // ~25px
$font-size-large: ceil(($font-size-base * 1.5)) !default; // ~30px

//@type general
$max-width: 1230px !default;
$padding-base: 15px;
$section-spacer-height: 20px;
$row-spacer-height: 20px;

/// Breakpoints map
$breakpoints: ('sbsiteSizeMobile': (max-width: 992px),
'mobile-m': (max-width: 375px),
'mobile-l': (max-width: 425px),
'extra-small': (max-width: 480px),
'small': (max-width: 768px),
'medium': (max-width: 992px),
'1100': (max-width: 1100px),
'large': (max-width: 1200px),
'larger': (max-width: 1300px),
'huge': (max-width: 1400px),
'1550': (max-width: 1550px),
'1750': (max-width: 1750px),
) !default;

//dir set
@if $dir !=ltr and $dir !=rtl {
	$dir: ltr;
}

//left / right according to dir
$left: if-ltr(right, left);
$right: if-ltr(left, right);

// Main colors
$accent: #b7e115;
$text: #000;
$title: #000;

//Hamburger color
$hamburger-layer-color: #fff;

//Header phone color
$header-phone-color: #fff;

//Loaders phone color
$loaders-primary-color: #000;

//Social color schemes
$clr-facebook: #3b5998;
$clr-youtube: #FF0000;
$clr-twitter: #1da1f2;
$clr-instagram: #c32aa3;
$clr-linkedin: #007bb5;
$clr-googleplus: #db4437;
$clr-whatsapp: #25d366;