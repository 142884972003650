.wrappper-search-branch{
  position: fixed;
  top: 35%;
  #{$left}: -250px;
  max-width: 250px;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  box-shadow: 3px 0px 15px 0px rgba(0,0,0,0.15);
  transition: all 0.5s;
  &_open{
    #{$left}: 0;
    & .search-branch-btn-toggle{
      width: 60px;
      #{$right}: -60px;
      &__close{
        display: none;
      }
      &__icon-close{
        display: block;        
      }
    }
  }
  
}

.search-branch-btn-toggle{
  position: absolute;
  top: 0;
  #{$right}: -90px;
  width: 90px;
  height: 80px;
  background-color: #fff;
  z-index: 1;
  border-radius: corner-values(0 50% 50% 0);
  cursor: pointer;
  box-shadow: 3px 0px 15px 0px rgba(0,0,0,0.15);
  @include respond-to('small'){
    #{$right}: -60px;
    width: 60px;
    height: 60px;
  }
  &__close{
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: #561f20;
    padding: side-values(16px 30px 5px 9px);
    @include respond-to('small'){
      padding: side-values(9px 17px 5px 9px);
      font-size: 14px;
    }
  }
  &__icon-close{
    color: #561f20;
    display: none;
    margin-top: 20px;
    margin-#{$right}: 17px;
    @include respond-to('small'){
      margin-top: 10px;
    }
  }
}

.search-branch-body{
  padding: 20px;
  position: relative;
  &__title{
    font-size: 16px;
    color: #000;
    line-height: 1.1;
  }
  &__sub-title{
    font-size: 22px;
    color: #561f20;
    font-weight: bold;
    line-height: 1.1;
  }
}

.search-branch-form{
  margin-top: 23px;
  position: relative;
  &__input{
    font-size: 18px;
    padding: 6px 15px;
    width: 100%;
    &_style{
      box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.1);
      background-color: #f6f6f6;
      color: #9f9f9f;
      border: 1px solid #d6d6d6;
      border-radius: 3px;
    }
  }
  &__input-btn{
    background-color: #86c430;
    color: #fff;
    font-weight: bold;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    &:active{
      @include btn-effect-active;
    }
  }
}