.investors {
	padding: 20px 0;
	background-color: #f9f9f9;
	text-align: center;
	// box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
	@include respond-to('extra-small') {
		padding: 15px 0;
	}
	&__slide-item {
		display: none;
		position: relative;
		height: 110px;
		min-width: 200px;
		line-height: 110px;
		@include respond-to('extra-small') {
			height: 70px;
			line-height: 70px;
		}
		&:active,
		&:focus {
			outline: none;
		}
		&:first-child {
			display: block;
		}
		& > img {
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
			display: inline;
			max-width: 90%;
			max-height: 90%;
		}
	}
}