.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 450px;
	@include respond-to('small') {
		// min-height: inherit;
	}
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 450px;
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
}
