.horizontal-form {
	position: relative;
	z-index: 1;
	padding: 25px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	// box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.15);
}

@keyframes expand {
  from {
    top: -50px;
  }
}

.alert-custom{
  display: block;
  height: auto;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
  margin: 0 auto;
  max-width: 720px;
  animation: expand .3s ease-in-out;
}

.form-right-decor {
	z-index: 1;
	position: absolute;
	bottom: -30px;
	right: -50px;
	pointer-events: none;
	max-width: 386px;
	width: 25vw;
	@include respond-to('1100') {
		min-width: 275px;
	}
	@include respond-to('extra-small') {
		display: none;
	}
}

.form-left-decor {
	display: none;
	position: absolute;
	bottom: 0;
	left: -50px;
	pointer-events: none;
	max-width: 277px;
	width: 15vw;
	@include respond-to('1100') {
		left: 0;
		min-width: 170px;
	}
	&_show {
		display: block;
		@include respond-to('extra-small') {
			display: none;
		}
	}
}

.section-limit {
	&_form {
		position: relative;
		z-index: 2;
		max-width: 1430px;
		width: 100%;
	}
}

.form-horizontal {
	padding: 20px 100px 15px;
	background-color: rgba($color: #ffeb00, $alpha: 0.6);
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
	@include respond-to('huge') {
		padding: 20px;
	}
	@include respond-to('small') {
		padding: 15px;
	}
	& .contact-us-form {
		overflow: hidden;
	}
	& .contact-us-form__title {
		margin-bottom: 20px;
		text-align: center;
		font-size: $font-size-base;
		font-weight: 700;
		color: #000;
		line-height: 1;
		@include respond-to('1100') {
			font-size: 20px;
		}
		@include respond-to('small') {
			font-size: 18px;
		}
		& span {
			font-size: $font-size-medium;
			font-weight: 800;
			@include respond-to('small') {
				font-size: $font-size-base;
			}
		}
	}
	& .contact-wrap-input {
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 15px;
		max-width: 230px;
		width: 100%;
		@include respond-to('larger') {
			max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		// &_check {
		// 	max-width: 108px;
		// 	@include respond-to('larger') {
		// 		max-width: 40%;
		// 		margin: 0;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		margin-top: 2px;
		// 		max-width: 44%;
		// 	}
		// }
		&_ml {
			margin-#{$left}: 0;
		}
		&_ta {
			max-width: 260px;
			@include respond-to('large') {
				margin-bottom: 10px;
			}
		}
		& textarea {
			height: 60px;
			padding: 17px 10px 0;
			max-width: 260px;
			@include respond-to('medium') {
				max-width: 100%;
			}
		}
		&_button {
			max-width: 130px;
			float: $left;
			margin: 0;
			// margin-#{$left}: 30px;
			@include respond-to('huge') {
				max-width: 125px;
			}
			@include respond-to('larger') {
				display: block;
				margin: 0 auto;
				max-width: 260px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				// margin-top: 10px;
				// max-width: 44%;
				min-width: 260px;
			}
		}
	}
	& .newslatter-input {
		padding: 19px 10px 18px;
		width: 100%;
		height: 100%;
		background-color: #fff;
		color: #a9a9a9;
		font-weight: 300;
		box-shadow: inset 0 0 10px 5px rgba($color: #f8f8f8, $alpha: 0.8);
		font-size: 18px;
		border: none;
		border-radius: 0;
		&::placeholder {
			font-size: 18px;
			color: #a9a9a9;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 60px;
		font-size: $font-size-medium;
		font-weight: 700;
		background-color: #ff5037;
		border: none;
		color: #fff;
		&:active {
			@include btn-effect-active;
		}
	}
}

.help-block {
	font-size: 16px;
}

.form-control:focus {
	border-radius: 0;
}

.ftr-check-uncheck {
	position: relative;
	display: inline-block;
	width: 23px;
	height: 23px;
	margin-#{$left}: 5px;
	margin-top: 5px;
	vertical-align: top;
	label {
		width: 23px;
		height: 23px;
		cursor: pointer;
		position: absolute;
		background-color: #eeeeee;
		border: 1px solid #bebebe;
		#{$left}: 0;
		top: 0;
		&::after {
			content: url(../images/check.png);
			position: absolute;
			top: -3px;
			#{$left}: 3px;
			opacity: 0;
			@include respond-to('larger') {
				top: 3px;
			}
		}
	}
	input[type=checkbox] {
		visibility: hidden;
		&:checked + label::after {
			opacity: 1;
		}
	}
	&_side {
		margin-top: 20px;
		label::after {
			top: -2px;
		}
	}
}

.ftr-checkbox-para {
	display: inline-block;
	max-width: 75px;
	@include respond-to('larger') {
		max-width: 150px;
	}
	& > p {
		font-size: 14px;
		font-weight: 300;
		color: #000;
		margin: 0;
		@include respond-to('extra-small') {
			font-size: 13px;
		}
	}
	&_side {
		margin-top: 15px;
		max-width: 128px;
	}
}
