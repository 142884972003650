.sb-slidebar {
  z-index: 99;
  background-color: #222222;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after{
  background-color: #222222;
  border-radius: 3px;
}

.mobile-menu-btn{
  float: $right;
  margin: side-values(18px 15px 0 10px);
}

.sb-left .sb-menu,
.sb-right .sb-menu {
  & li {
    border-bottom: 0;
    &.hasSub {
			position: relative;
			& i{
				position: absolute;
				top: 15px;
				#{$left}: 15px;
				z-index: 0;
			}
      &.hasSub-open {
				background-color: hsla(0,0%,100%,.05);
        &>ul {
					display: block;
					& > li > a{
						& > i{
							position: absolute;
							top: 20px;
							#{$right}: 15px;
							z-index: 9;
						}
					}
        }
      }
    }
    & a {
      color: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
			font-size: 15px;
			&:hover{
				background-color: unset;
			}
    }
    & ul {
      display: none;
      li {
        padding-#{$right}: 0;
      }
    }
  }
  & ul {
    padding: 0;
    li > a {
      padding-#{$right}: 35px;
			position: relative;
			& > i{
				position: absolute;
				top: 20px;
				#{$right}: 15px;
				z-index: 9;
			}
    }
  }
}