.form-block {
	padding: 40px 0;
	@include respond-to('medium') {
		padding: 25px 0;
	}
	@include respond-to('extra-small') {
		overflow-x: auto;
		padding: 5px 0 15px;
	}
	&__table {
		width: 100%;
		min-width: 450px;
		& tr {
			border-bottom: 1px solid #ebebeb;
		}
	}
	&__fields {
		padding: 50px 0;
		@include respond-to('medium') {
			padding: 25px 0;
		}
		@include respond-to('extra-small') {
			padding: 25px 0 10px;
		}
	}
	&__button {}
}

.table-title {
	padding: 15px 0;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	color: #000;
	@include respond-to('medium') {
		font-weight: 400;
	}
	@include respond-to('small') {
		font-size: 14px;
	}
}

.table-title,
.form-radio {
	width: 15%;
}

.form-radio-title {
	padding: 10px 0;
	font-size: 18px;
	font-weight: 700;
	color: #000;
	@include respond-to('medium') {
		padding: 5px 0;
		font-size: 16px;
		font-weight: 400;
	}
	@include respond-to('small') {
		font-size: 14px;
	}
}

.table-col-1 {
	width: 40%;
	padding-#{$right}: 80px;
	text-align: $right;
	@include respond-to('medium') {
		padding-#{$right}: 20px;
	}
	@include respond-to('small') {
		padding-#{$right}: 0;
	}
}