.main-header {
	position: fixed;
	z-index: 99;
	top: 0;
	width: 100%;
	background: #8FCDFE; //cec3fd
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
	& > .section-limit {
		position: relative;
		@include respond-to('medium') {
			padding: 0;
		}
	}
}

.header-tel{
  position: absolute;
  top: 3px;
  left: 0;
  color: #000 !important;
}

.header-desktop {
	display: inline-block;
	width: 100%;
	height: 100px;
	transition: height 0.4s ease;
	text-align: $left;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	position: absolute;
	top: 0;
	#{$right}: 0;
	vertical-align: top;
	@include respond-to('1100') {
		top: 10px;
		& img {
			max-width: 190px;
		}
	}
}

.main-navi {
	display: inline-block;
  text-align: $right;
  position: relative;
	& > ul {
		display: inline-block;
		margin-top: 33px;
		padding: 0;
		padding-#{$right}: 230px;
		list-style: none;
		transition: margin 0.3s ease;
		@include respond-to('1100') {
			padding-#{$right}: 190px;
		}
		& > li {
			position: relative;
			float: $right;
			margin-#{$left}: 35px;
			@include respond-to('large') {
				margin-#{$left}: 25px;
			}
			@include respond-to('1100') {
				margin-#{$left}: 20px;
			}
			&:last-child {
				margin-#{$left}: 0;
			}
			& > a {
				display: block;
				padding: 3px 0;
				border-bottom: 2px solid transparent;
				color: #000;
				transition: border 0.3s ease-in-out;
				&:hover {
					border-bottom: 1px solid #fff;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 1px solid #fff;
					color: #fff;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
		float: $right;
	}
	&__logo {
		position: absolute;
		left: 0;
		top: 0;
		& img {
			max-width: 165px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 19px;
		i {
			color: $header-phone-color;
			font-size: 32px;
		}
	}
	& .mobile-menu-btn {
		margin-top: 20px;
		@include respond-to('mobile-l') {
			// margin-#{$right}: 0;
		}
	}
}

.hamburger-inner {
	background-color: $hamburger-layer-color;
	&::before,
	&::after {
		background-color: $hamburger-layer-color;
	}
}