.wrap-article-box {
	display: block;
	&:hover {
		.article-box-image__item {
			transform: scale(1.07);
		}
	}
	& hr {
		margin: 40px 0;
		@include respond-to('medium') {
			margin: 25px 0;
		}
	}
	&:nth-child(3n + 1) {
		& .article-box-btn {
			border-top: 2px solid #b3dd14;
			@include link_no-hover(#b3dd14);
		}
	}
	&:nth-child(3n + 2) {
		& .article-box-btn {
			border-top: 2px solid #ff5037;
			@include link_no-hover(#ff5037);
		}
	}
	&:nth-child(3n + 3) {
		& .article-box-btn {
			border-top: 2px solid #5dc5fa;
			@include link_no-hover(#5dc5fa);
		}
	}
}

.article-box-image {
	overflow: hidden;
	width: 100%;
	height: 160px;
	border: 1px solid #c8c8c8;
	@include respond-to('small') {
		max-width: 275px;
		margin: 0 0 15px;
	}
	&__item {
		background-position: center center;
		background-size: cover;
		width: 100%;
		height: 160px;
		transition: all 0.6s;
	}
}

.article-box-data {
	padding-#{$right}: 10px;
	&__title {
		font-size: $font-size-medium;
		font-weight: 700;
		color: #000;
		line-height: 1;
		margin-bottom: 15px;
		@include respond-to('extra-small') {
			font-size: 22px;
			margin: 10px 0 15px;
		}
	}
	&__content {
		max-width: 470px;
		width: 100%;
		overflow: hidden;
		text-align: justify;
		& > p {
			font-size: 16px;
			color: #696969;
			margin: 0;
			line-height: 1.2;
		}
	}
}

.article-box-btn {
	display: inline-block;
	margin-top: 30px;
	margin-#{$right}: 10px;
	padding-top: 8px;
	font-size: 20px;
	font-weight: 700;
	@include respond-to('small') {
		margin-top: 20px;
	}
}
