.banner-nav {
	width: 100%;
	z-index: 1;
}

.wrapper-all-content {
	// background-color: #fff;
	position: relative;
	width: 100%;
}

.sec-padd {
	padding: 45px 0 50px;
	@include respond-to('1100') {
		padding: 35px 0 40px;
	}
	@include respond-to('small') {
		padding: 25px 0;
	}
}

.hp-about {
	position: relative;
	overflow: hidden;
	z-index: 1;
	text-align: center;
	padding: 70px 0 45px;
	@include respond-to('1100') {
		padding: 50px 0 30px;
	}
	@include respond-to('small') {
		padding: 25px 0;
	}
	&__title {
		margin-bottom: 40px;
		@include respond-to('1100') {
			margin-bottom: 30px;
		}
		@include respond-to('small') {
			margin-bottom: 20px;
		}
	}
	&__link {
		margin-top: 40px;
		@include respond-to('1100') {
			margin-top: 30px;
		}
		@include respond-to('small') {
			margin-top: 20px;
		}
		@include respond-to('extra-small') {
			margin-top: 10px;
		}
	}
	&__desc {
		max-width: 1100px;
		width: 100%;
		margin: 0 auto;
		@include respond-to('1550') {
			max-width: 950px;
		}
	}
	& .section-limit {
		position: relative;
		z-index: 2;
	}
}

.hp-top-decor {
	position: absolute;
	left: 0;
	bottom: 0;
	max-width: 531px;
	min-width: 445px;
	width: 27vw;
	@include respond-to('1750') {
		left: -80px;
	}
	@include respond-to('1550') {
		left: -120px;
	}
	@include respond-to('huge') {
		opacity: 0.7;
		min-width: 355px;
	}
	@include respond-to('large') {
		display: none;
	}
}

.hp-team-sec {
	background-color: #f9f9f9;
}

.teams {
	padding-top: 55px;
	font-size: 0;
	text-align: center;
	@include respond-to('1100') {
		padding-top: 35px;
	}
	@include respond-to('small') {
		padding-top: 25px;
	}
	&__item {
		display: inline-block;
		width: 25%;
    vertical-align: top;
    margin-bottom: 30px;
		@include respond-to('small') {
			width: 50%;
			margin-bottom: 20px;
		}
		@include respond-to('mobile-l') {
			width: 100%;
		}
		&:nth-child(4n + 1) {
			& .homepage-box::after {
				background-color: #51c1f7;
			}
		}
		&:nth-child(4n + 2) {
			& .homepage-box::after {
				background-color: #b4de0d;
			}
		}
		&:nth-child(4n + 3) {
			& .homepage-box::after {
				background-color: #fce500;
			}
		}
		&:nth-child(4n + 4) {
			& .homepage-box::after {
				background-color: #ff5037;
			}
		}
	}
}

.homepage-box {
	display: block;
	position: relative;
	// width: 205px;
	width: 100%;
	height: 300px;
	margin: 0 auto;
	@include link_no-hover(#000);
	@include respond-to('small') {
		height: 275px;
	}
	&:hover {
		& .homepage-box__photo {
			transform: scale(1.03);
		}
	}
	&_h350 {
		height: 350px;
		@include respond-to('medium') {
			height: 300px;
		}
		&::after {
			display: none;
		}
	}
	&__photo {
		margin: 0 auto 20px;
		overflow: hidden;
		border-radius: 50%;
		width: 200px;
		height: 200px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: transform 0.3s ease;
		@include respond-to('medium') {
			margin: 0 auto 15px;
			width: 170px;
			height: 170px;
		}
		&_shadow {
			width: 195px;
			height: 195px;
			margin: 0 auto 25px;
			box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.1);
			@include respond-to('medium') {
				width: 170px;
				height: 170px;
				margin: 0 auto 15px;
			}
		}
	}
	&__info {
		padding: 0 15px;
	}
	&__info-title {
		line-height: 1;
		font-size: $font-size-medium;
		font-weight: 700;
		color: $text;
		@include respond-to('1100') {
			font-size: 22px;
		}
		&_fz35 {
			font-size: 35px;
			margin-bottom: 25px;
			@include respond-to('1100') {
				font-size: 25px;
			}
			@include respond-to('medium') {
				margin-bottom: 15px;
				font-size: 22px;
			}
		}
	}
	&__info-desc {
		font-size: $font-size-base;
		font-weight: 400;
		color: $text;
		&_branch {
			line-height: 1.2;
			@include respond-to('medium') {
				font-size: 18px;
			}
		}
	}
	&::after {
		content: '';
		position: absolute;
		bottom: -5px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 70px;
		height: 5px;
		background-color: #000;
	}
}

.hp-branch-sec {
	position: relative;
	background-color: #ffff;
}

.branch {
	position: relative;
	z-index: 1;
	padding-top: 55px;
	font-size: 0;
	text-align: center;
	@include respond-to('1100') {
		padding-top: 35px;
	}
	@include respond-to('small') {
		padding-top: 25px;
	}
	&__item {
		display: inline-block;
		width: 25%;
		vertical-align: top;
		@include respond-to('small') {
			width: 50%;
			margin-bottom: 20px;
		}
		@include respond-to('mobile-l') {
			width: 100%;
		}
	}
}

.branch-decor-img {
	position: absolute;
	left: 0;
	bottom: 0;
	pointer-events: none;
	max-width: 327px;
	width: 17vw;
	@include respond-to('1750') {
		left: -40px;
	}
	@include respond-to('1550') {
		display: none;
	}
}


.branch {
	position: relative;
	z-index: 1;
	padding-top: 45px;
	padding-bottom: 20px;
	margin: 0 -13px;
	font-size: 0;
	text-align: center;
	@include respond-to('larger') {
		padding-top: 25px;
	}
	@include respond-to('large') {
		padding-top: 5px;
		padding-bottom: 15px;
	}
	@include respond-to('medium') {
		margin: 0 -5px;
	}
	&__item {
		display: inline-block;
		width: 25%;
		height: 336px;
		vertical-align: top;
		padding: 13px;
		@include respond-to('medium') {
			padding: 13px 5px;
		}
		@include respond-to('small') {
			width: 50%;
			// margin-bottom: 20px;
		}
		@include respond-to('mobile-l') {
			width: 100%;
		}
	}
	& .slick-dots {
		bottom: -20px;
		& li {
			margin: 0;
			padding: 0;
			height: 22px;
			& button {
				padding: 0;
				&::before {
					content: "";
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 8px;
					height: 8px;
					border: 1px solid #8fcdfe;
					border-radius: 50%;
					opacity: 1;
				}
			}
		}
		& li.slick-active {
			& button {
				&::before {
					border: 1px solid #8fcdfe;
					background-color: #8fcdfe;
					opacity: 1;
				}
			}
		}
	}
}


.branch-box {
	overflow: hidden;
	display: block;
	max-width: 282px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	border: 1px solid #d8d8d8;
	background-color: #fff;
	padding: 9px;
	@include link_no-hover(#000);
  box-shadow: 0 5px 15px 0 rgba($color: #000000, $alpha: 0.05);
 
	&__img {
		width: 100%;
		height: 200px;
		background-position: center;
		background-size: cover;
    background-repeat: no-repeat;
    position: relative;
		@include respond-to('medium') {
			height: 190px;
		}
  }
  &__googlemap{
    position: absolute;
    top: 5px;
    #{$right}: 5px;
    margin: 0 !important;
    & img{
      margin: 0 !important;
    }
  }
  &__wazemap{
    position: absolute;
    top: 5px;
    #{$right}: 55px;
    & img{
      margin: 0 !important;
    }
  }
  &__phone{
    position: absolute;
    top: 5px;
    #{$right}: 105px;
    & img{
      margin: 0 !important;
    }
  }
	&__info {
		padding: 10px 0 10px;
	}
	&__info-title {
		overflow: hidden;
		max-height: 40px;
		margin-bottom: 10px;
		font-size: 20px;
		font-weight: 800;
		text-align: center;
		line-height: 1;
	}
	&__info-desc {
		overflow: hidden;
		max-height: 55px;
		max-width: 90%;
		margin: 0 auto;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		line-height: 1.2;
	}
}


.slick-next:before, .slick-prev:before{
  color: #000;
}

[dir=rtl] .slick-prev{
  right: -25px;
}

[dir=rtl] .slick-next{
  left: -25px;
}